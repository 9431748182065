import {baseChartOption, mergeObjects} from '@/libs/charts/main'
import i18n from '@/i18n/i18n'

const dashedLineChartOption = () => {
    return {
        ...baseChartOption,
        chart: {
            type: 'line',
            width: '100%'
        },
        stroke: {
            width: 2,
            curve: 'straight'
            //dashArray: 5
        },
        yaxis: {
            labels: {
                formatter: (item) => {
                    return `${(item / 1000000).toFixed(2)} €`
                }
            }
        },
        noData: {
            text: i18n.t('general.no_data')
        }
    }
}

function getConfig(rawConfig) {
    if (rawConfig !== null) {
        return mergeObjects(dashedLineChartOption(), rawConfig)
    } else {
        return dashedLineChartOption()
    }
}
export default {
    getConfig
}
