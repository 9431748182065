<template>
    <div>
        <b-button variant="primary" @click="modalActive = true" class="float-right mr-1"><feather-icon icon="SettingsIcon"/></b-button>

        <b-modal :title="$t('graphs.edit_graphs_modal_title')" body-class="min-height-500" style="height:500px; font-size:large;" size="lg" v-model="modalActive" no-close-on-backdrop @close="modalActive = false">
            <template #default>
                <div v-if="availableGraphsToday.length > 0">
                    <b-card-title>{{$t('graphs.edit_graphs_today')}}</b-card-title>
                    <v-select class="mb-2" :options="availableGraphsToday" v-model="graphsToday" :getOptionLabel="getOptionLabel" multiple/>
                    <draggable v-model="graphsToday">
                        <tr style="cursor: pointer; height:50px;" v-for="(graph, index) in graphsToday" :key="graph.id" class="m-2 border">
                            <td class="px-2"><fa icon="arrows-up-down" color="#B4BFB5" /></td>
                            <td class="px-2">{{index + 1}} - {{$t(`graphs.${graph}`)}}</td>
                            <td class="px-2"><b-button size="sm" @click="removeGraph(graph)"><feather-icon icon="Trash2Icon"/></b-button></td>
                        </tr>
                    </draggable>
                </div>
                <div v-if="availableGraphsPast.length > 0">
                    <b-card-title>{{$t('graphs.edit_graphs_history')}}</b-card-title>
                    <v-select class="mb-2" :options="availableGraphsPast" v-model="graphsPast" :getOptionLabel="getOptionLabel" multiple/>
                    <draggable v-model="graphsPast">
                        <tr style="cursor: pointer; height:50px;" v-for="(graph, index) in graphsPast" :key="graph.id" class="m-2 border">
                            <td class="px-2"><fa icon="arrows-up-down" color="#B4BFB5" /></td>
                            <td class="px-2">{{index + 1}} - {{$t(`graphs.${graph}`)}}</td>
                            <td class="px-2"><b-button size="sm" @click="removeGraph(graph)"><feather-icon icon="Trash2Icon"/></b-button></td>
                        </tr>
                    </draggable>
                </div>

            </template>

            <template #modal-footer>
                <b-button variant="primary" @click="saveSettings">
                    <feather-icon icon="SaveIcon" class="mr-50"/>
                    <span class="align-middle">{{ $t('general.save') }}</span>
                </b-button>
            </template>
        </b-modal>
    </div>
</template>
<script>
    import {BButton, BCardTitle, BModal} from 'bootstrap-vue'
    import draggable from 'vuedraggable'
    import chartConfigs from '@/libs/charts/chartsConfigs/main'
    import vSelect from 'vue-select'
    import {ChartType, PastCharts, TodayCharts} from '@/libs/enums/Charts'
    import * as Sentry from '@sentry/vue'
    import {Tiers} from '@/libs/enums/Tiers'

    export default {
        components: {
            BCardTitle,
            BModal,
            //BFormCheckbox,
            BButton,
            draggable,
            vSelect
        },

        data() {
            return {
                chartConfigs,
                availableGraphsToday: [],
                availableGraphsPast: [],
                availableGraphs: [],
                modalActive: false,
                graphsToday: [],
                graphsPast: []
            }
        },

        methods: {
            async saveSettings() {
                const graphs = [
                    ...this.graphsToday,
                    ...this.graphsPast
                ]

                try {
                    await this.$http.post('/api/dashboard/v1/dashboard/config', graphs)
                    this.$printSuccess(this.$t('print.success.settings_saved'))
                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(this.$t('print.error.general'))
                } finally {
                    this.modalActive = false
                }

                this.$emit('save')
            },

            async loadData() {
                try {
                    const response =  await this.$http.get('/api/dashboard/v1/dashboard/config')
                    this.availableGraphs = response.data
                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(this.$t('print.error.general'))
                } finally {
                    await this.prepareData()
                }
            },

            getOptionLabel(option) {
                return this.$t(`graphs.${option}`)
            },

            checkArr(graphs, type) {
                const returnArr = []
                if (type === 'today') {
                    for (let i = 0; i < graphs.length; i++) {
                        if (TodayCharts.includes(graphs[i])) {
                            returnArr.push(graphs[i])
                        }
                    }
                } else if (type === 'past') {
                    for (let i = 0; i < graphs.length; i++) {
                        if (PastCharts.includes(graphs[i])) {
                            returnArr.push(graphs[i])
                        }
                    }
                }
                return returnArr
            },

            removeGraph(graph) {
                this.graphsToday = this.graphsToday.filter(x => x !== graph)
                this.graphsPast = this.graphsPast.filter(x => x !== graph)
            },

            async prepareData() {
                this.availableGraphsToday = TodayCharts
                this.availableGraphsPast = PastCharts
                if (this.clientData.tier === Tiers.Essential) {
                    this.availableGraphsPast.splice(this.availableGraphsPast.indexOf(ChartType.TablesHeatmap), 1)
                }
                this.graphsToday = this.checkArr(this.availableGraphs, 'today')
                this.graphsPast = this.checkArr(this.availableGraphs, 'past')
            }

        },

        computed: {
            'clientData'() {
                return this.$store.getters['client/clientData']
            }
        },

        async mounted() {
            await this.loadData()
        }
    }
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
<style>
    .min-height-500 {
        min-height: 500px;
    }
</style>