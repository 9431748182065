<template>
    <b-modal v-if="openTab" :title="$t('graphs.open_order_details')" @hide="openTab = null" size="xl" class="w-50" v-model="modalActive" hide-footer>
        <b-container>
            <b-row class="m-3">
                <div class="col-12 col-md-6 mt-2 mt-md-0">
                    <div class="billInfo data-border h-100">
                        <div class="border-text bgCard" style="white-space: nowrap;overflow: hidden;text-overflow: ellipsis;">
                            {{$t('reporting.issued_bills.bill')}}
                        </div>
                        <div class=" px-1 pt-1">
                            <div>
                                {{$t('graphs.open_tab_created_at')}} {{ dayjs(openTab.timestamp).format("DD.MM.YYYY ob H:mm:ss") }}
                            </div>
                            <div>
                                {{$t('reporting.issued_bills.work_space')}}: {{ openTab.room_name }}, {{ openTab.table_name }}
                            </div>
                            <div>
                                {{$t('reporting.issued_bills.waiter')}}: {{ openTab.waiter }}
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-12 col-md-6">
                    <div class="billInfo data-border h-100">
                        <div class="border-text bgCard">
                            {{$t('general.details')}}
                        </div>
                        <div class="px-1 pt-1">
                            <div>
                                {{$t('general.business_unit')}}: {{ openTab.business_unit }}
                            </div>
                            <div>
                                {{$t('reporting.archived.cash_register_id')}}: {{ openTab.cash_register }}
                            </div>
                            <div v-if="openTab.notes !== ''">
                                {{$t('reporting.archived.notes')}}: {{ openTab.notes }}
                            </div>
                            <div>
                                {{$t('reporting.archived.total_price')}}: {{numberRounding(openTab.total_price)}} €
                            </div>
                        </div>
                    </div>
                </div>
            </b-row>
        </b-container>

        <CustomTable
            :items="products"
            :fields="columnDefs"
            :showTableOptions="false"
            class="mt-2"
        />
    </b-modal>
</template>
<script>
    import {BModal, BContainer, BRow} from 'bootstrap-vue'
    import CustomTable from '@/components/CustomTable.vue'
    import {Formaters} from '@/libs/formaters'

    export default {
        components: {
            CustomTable,
            BModal,
            BContainer,
            BRow
        },
        data() {
            return {
                products: [],
                openTab: null,
                modalActive: false,
                columnDefs: [
                    {
                        label: this.$t('table_fields.name'),
                        key: 'product.name'
                    },
                    {
                        label: this.$t('table_fields.category'),
                        key: 'product.product_category.name'
                    },
                    {
                        label: this.$t('table_fields.tax_rate'),
                        key: 'product.vat_group.percentage',
                        formatter: Formaters.formatQuantity
                    },
                    {
                        label: this.$t('table_fields.original_price'),
                        key: 'original_price',
                        formatter: Formaters.formatPrice
                    },
                    {
                        label: this.$t('table_fields.quantity'),
                        key: 'quantity',
                        formatter: Formaters.formatQuantity
                    },
                    {
                        label: this.$t('table_fields.discount_percentage'),
                        key: 'discount',
                        formatter: Formaters.formatQuantity
                    },
                    {
                        label: this.$t('table_fields.total_price'),
                        key: 'total_price',
                        formatter: Formaters.formatPrice
                    },
                    {
                        label: this.$t('table_fields.notes'),
                        key: 'notes'
                    },
                    {
                        label: this.$t('table_fields.selected_options'),
                        key: 'selected_options',
                        formatter: this.formatOptions
                    }
                ]
            }
        },
        methods: {
            open(data) {
                if (data !== null) {
                    this.products = data.products
                    this.openTab = data
                }
                this.modalActive = true
            },
            formatOptions(row) {
                return row.map(option => option.name).join(', ')
            },
            numberRounding(number) {
                return this.$numberRounding(number)
            }
        }
    }
</script>

<style scoped>
.billInfo {
    font-size: 18px;
}

.data-border {
    border: 1px solid #646464;
    margin: 5px;
    padding: 10px;
    position: relative;
    border-radius: 18px;
}

.border-text {
    position: absolute;
    top: -12px;
    left: 20px;
    background: white;
    padding: 0 15px;
    font-weight: bold;

}
</style>