<template>
    <div id="restaurant" class="background overflow-hidden">
        <div class="d-inline-flex flex-wrap overflow-hidden" style="width: 100%; height: 100%;">
            <div class="m-0" style="height: 100%; width:100%;">
                <canvas id="restaurantTables" style="height: 100%; border-radius: 8px;"/>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        computed: {
            theme() {
                return this.$store.getters['appConfig/currentSkin']
            },
            themeColors() {
                if (this.theme === 'dark') {
                    return this.dark
                } else if (this.theme === 'light') {
                    return this.light
                }

                return {}
            }
        },
        props: {
            tables: {
                type: Array,
                default: () => { return [] }
            }
        },
        data() {
            return {
                canvas: null,
                ctx: null,
                resizeEventId: null,
                light: {
                    background: '#e7e7e7',
                    table: '#d8d8d8',
                    textColor: '#222222',
                    textColorDark: '#e0e0e0'
                },
                dark: {
                    background: '#292929',
                    table: '#343434',
                    textColor: '#222222',
                    textColorDark: '#e0e0e0'
                },
                colorTreshold: 4
            }
        },
        methods: {
            getDynamicFontSize() {
                const screenWidth = window.innerWidth

                if (screenWidth < 576) {
                    return 12
                } else if (screenWidth < 768) {
                    return 16
                } else {
                    return 20
                }
            },
            drawRect(item, lineWidth) {
                const table = this.tables.find(ele => ele.id === item.id)

                if (item.hidden || (item.x === 0 && item.y === 0)) return

                let color = ''

                if (this.theme === 'dark') {
                    color = item.color_dark
                } else {
                    color = item.color
                }

                //Sama miza
                this.ctx.beginPath()
                this.ctx.fillStyle = color //this.themeColors.selectedTable
                this.ctx.strokeStyle = color //this.themeColors.selectedTable


                const x = this.canvas.width * (item.x / 10000)
                const y = this.canvas.height * (item.y / 10000)
                const xSize = this.canvas.width * (item.x_size / 10000)
                const ySize = this.canvas.height * (item.y_size / 10000)


                this.ctx.rect(x, y, xSize, ySize)
                this.ctx.fill()

                if (this.getContrastRatio(color, this.themeColors.textColorDark) > this.colorTreshold) {
                    this.ctx.fillStyle = this.themeColors.textColorDark
                } else {
                    this.ctx.fillStyle = this.themeColors.textColor
                }


                //Sredinska stevilka mize
                this.ctx.font = `bold ${this.getDynamicFontSize()}px montserrat`
                let xText = x + (xSize / 2)
                let yText = y + (ySize / 2) - 5

                this.ctx.lineWidth = lineWidth
                this.ctx.textAlign = 'center'
                this.ctx.fillText(item.name, xText, yText)
                this.ctx.stroke()

                if (!table) {
                    this.ctx.font = 'bold 20px montserrat'
                    xText = x + (xSize / 2)
                    yText = y + (ySize / 2) + 17

                    this.ctx.textAlign = 'center'
                    this.ctx.stroke()
                }
            },
            drawCircle(item, lineWidth) {
                if (item.hidden || (item.x === 0 && item.y === 0)) return

                const x = this.canvas.width * (item.x / 10000)
                const y = this.canvas.height * (item.y / 10000)
                const r = this.canvas.height * (item.r / 10000)

                const table = this.tables.find(ele => ele.id === item.id)

                this.ctx.beginPath()

                let color = ''

                if (this.theme === 'dark') {
                    color = item.color_dark
                } else {
                    color = item.color
                }

                this.ctx.fillStyle = color //this.themeColors.selectedTable
                this.ctx.strokeStyle = color //this.themeColors.selectedTable

                this.ctx.lineWidth = lineWidth
                this.ctx.arc(x, y, r, 0, 2 * Math.PI)
                this.ctx.fill()

                if (this.getContrastRatio(color, this.themeColors.textColorDark) > this.colorTreshold) {
                    this.ctx.fillStyle = this.themeColors.textColorDark
                } else {
                    this.ctx.fillStyle = this.themeColors.textColor
                }
                this.ctx.textAlign = 'center'
                this.ctx.font = 'bold 20px montserrat'
                this.ctx.fillText(item.name, x, y - 5)
                this.ctx.stroke()

                if (!table) {
                    this.ctx.font = 'bold 20px montserrat'
                    this.ctx.textAlign = 'center'
                    this.ctx.stroke()
                }

            },
            drawAll() {
                this.ctx.beginPath()
                this.ctx.fillStyle = this.themeColors.background
                this.ctx.fillRect(0, 0, this.canvas.width, this.canvas.height)

                for (const item of this.tables) {
                    if (item.shape_type === 1) {
                        this.drawRect(item, 1)
                    } else if (item.shape_type === 2) {
                        this.drawCircle(item, 1)
                    }
                }
            },
            resizeCanvas() {
                const thisInst = this
                const parent = document.getElementById('parentDiv')
                const styles = getComputedStyle(parent)

                const parentWidth = Math.max(parseInt(styles.getPropertyValue('width'), 10), 500)
                const parentHeight = parseInt(styles.getPropertyValue('height'), 10)

                thisInst.ctx.canvas.width = parentWidth
                thisInst.ctx.canvas.height = parentHeight

                this.drawAll()
            },
            contrastRatio(l1, l2) {
                // Ensure l1 is the lighter color and l2 is the darker color
                if (l1 < l2) {
                    [l1, l2] = [l2, l1]
                }
                return (l1 + 0.05) / (l2 + 0.05)
            },
            getContrastRatio(hex1, hex2) {
                // Convert hex colors to RGB
                const rgb1 = this.hexToRgb(hex1)
                const rgb2 = this.hexToRgb(hex2)

                // Calculate luminance for each color
                const lum1 = this.luminance(rgb1.r, rgb1.g, rgb1.b)
                const lum2 = this.luminance(rgb2.r, rgb2.g, rgb2.b)

                // Calculate the contrast ratio
                return this.contrastRatio(lum1, lum2)
            },
            hexToRgb(hex) {
                hex = hex.replace(/^#/, '')

                const r = parseInt(hex.substring(0, 2), 16)
                const g = parseInt(hex.substring(2, 4), 16)
                const b = parseInt(hex.substring(4, 6), 16)

                return {
                    r,
                    g,
                    b
                }
            },
            luminance(r, g, b) {
                const a = [r, g, b].map((v) => {
                    v /= 255
                    return v <= 0.03928 ? v / 12.92 : Math.pow((v + 0.055) / 1.055, 2.4)
                })

                // Calculate the luminance
                return ((a[0] * 0.2126) + (a[1] * 0.7152) + (a[2] * 0.0722))
            }
    
        },
        mounted() {
            this.canvas = document.getElementById('restaurantTables')
            this.ctx = this.canvas.getContext('2d')

            const parent = this.canvas.parentNode
            const styles = getComputedStyle(parent)
            this.ctx.canvas.width  = parseInt(styles.getPropertyValue('width'), 10)
            this.ctx.canvas.height = parseInt(styles.getPropertyValue('height'), 10)


            window.addEventListener('resize', this.resizeCanvas)
        },
        beforeDestroy() {
            window.removeEventListener('resize', this.resizeCanvas)
        },
        watch: {
            tables() {
                this.drawAll()
            },
            theme() {
                this.drawAll()
            }
        }
    }
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';

.background{
    height: 65vh;
}

@media (max-width: (map-get($grid-breakpoints, sm))) {
    .background{
        height: 55vh;
    }
}

@media (max-width: (map-get($grid-breakpoints, lg))) {
    .background{
        height: 60vh;
    }
}

.outerHeightInternal{
    //height: calc(100% - 100px) !important;
    height: 100%;
    width: 100%;
}

</style>
