<template>
    <div id="restaurant" class="background" style="border-radius: 8px;">
        <div class="d-inline-flex overflow-y-auto flex-wrap" style="width: 100%; height: 100%; border-radius: 8px;">
            <div class="row m-0 hideScrollbar px-5" style="height: 100%; width:100%; overflow-y: scroll;">
                <div class="col-4 col-sm-4 col-md-4 col-lg-3 col-xl-2 my-1 p-custom" v-for="(table, indexTable) in tables" :key="`table-list-view-${indexTable}`">
                    <div  class="dot bg-table flex-column center justify-content-center" style="display: flex; width: 100%;" :style="'background-color:' + getColor(table) + '!important;'">
                        <div class="font-weight-bolder font-large-1 font d-flex justify-content-center text-white" style="width: 100%; height: 100%; align-items: center; text-align: center; word-break: break-all;">{{table.name}}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
            }
        },
        computed: {
            theme() {
                return this.$store.getters['appConfig/currentSkin']
            }
        },
        props: {
            tables: {
                type: Array
            }
        },
        methods: {
            getColor(table) {
                if (this.theme === 'dark') {
                    return table.color_dark
                } else {
                    return table.color
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';

.background{
  height: 65vh;
    background: #e7e7e7;
}

@media (max-width: (map-get($grid-breakpoints, sm))) {
    .p-custom {
        padding: 4px;
    }
    .font-large-1 {
        font-size: 1rem!important;
    }
    .background{
        height: 55vh;
    }
}

@media (max-width: (map-get($grid-breakpoints, lg))) {
    .background{
        height: 60vh;
    }
}

.dot {
  //width: 100%;
  aspect-ratio: 1/1;
  border-radius: 50%;
  display: inline-block;
  max-width: 270px;
    //background: #2a2a2a;
}

.hideScrollbar{
    -ms-overflow-style: none; /* for Internet Explorer, Edge */
    scrollbar-width: none; /* for Firefox */
    overflow-y: scroll;
}
.hideScrollbar::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
}

.dark-layout .background {
    background: #2a2a2a!important;
}

@media screen {

}
</style>
