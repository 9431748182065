<template>
    <div>
        <b-modal size="md" :title="$t('general.average')" v-model="modalActive" no-close-on-backdrop @close="modalActive = false">
            <template #default>
                <validation-observer ref="validation">
                    <b-form-group :label="$t('form_data.average_price')">
                        <validation-provider #default="{ errors }" :name="$t('form_data.average_price')" rules="required">
                            <b-input-group append="€">
                                <b-form-input type="number" v-model.number="average"></b-form-input>
                            </b-input-group>
                            <small class="text-danger">{{errors[0]}}</small>
                        </validation-provider>
                    </b-form-group>
                </validation-observer>
            </template>

            <template #modal-footer>
                <b-button variant="primary" @click="resetSettings">
                    <feather-icon icon="XIcon" class="mr-50"/>
                    <span class="align-middle">{{ $t('general.revert') }}</span>
                </b-button>
                <b-button variant="primary" @click="saveSettings">
                    <feather-icon icon="SaveIcon" class="mr-50"/>
                    <span class="align-middle">{{ $t('general.save') }}</span>
                </b-button>
            </template>
        </b-modal>
    </div>
</template>
<script>
    import {BButton, BModal, BFormInput, BInputGroup, BFormGroup} from 'bootstrap-vue'
    import * as Sentry from '@sentry/vue'
    import {ValidationProvider, ValidationObserver} from 'vee-validate'
    export default {
        components: {
            BFormGroup,
            ValidationProvider,
            ValidationObserver,
            BModal,
            BButton,
            BFormInput,
            BInputGroup
        },

        data() {
            return {
                modalActive: false,
                average: 0
            }
        },

        methods: {
            async saveSettings() {
                try {
                    await this.$http.post('/api/dashboard/v1/dashboard/config/average_price', {average: this.average * 1000000})
                    this.$printSuccess(this.$t('print.success.settings_saved'))
                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(this.$t('print.error.general'))
                } finally {
                    this.modalActive = false
                    this.$emit('saved')
                }

                this.$emit('save')
            },

            async resetSettings() {
                try {
                    await this.$http.post('/api/dashboard/v1/dashboard/config/average_price', {average: 0})
                    this.$printSuccess(this.$t('print.success.settings_saved'))
                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(this.$t('print.error.general'))
                } finally {
                    this.modalActive = false
                    this.$emit('saved')
                }

                this.$emit('save')
            },

            async open() {
                await this.loadData()
                this.modalActive = true
            },

            async loadData() {
                try {
                    const response =  await this.$http.get('/api/dashboard/v1/dashboard/config/average_price')
                    this.average = response.data
                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(this.$t('print.error.general'))
                }
            }
        },

        async mounted() {
            await this.loadData()
        }
    }
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
<style>
    .min-height-500 {
        min-height: 500px;
    }
</style>