<template>
    <div>
        <b-overlay :show="!dataLoaded">
            <b-card
                no-body
                class="card-statistics"
            >
                <b-card-header>
                    <div class="d-flex justify-content-between w-100">
                        <div>
                            <b-card-title>{{$t('graphs.statistics')}}</b-card-title>
                            <b-card-text class="font-small-2 mr-25 mb-0">
                                {{$t('graphs.last_updated')}}: {{lastUpdatedFormated}}
                            </b-card-text>
                        </div>
                        <fa
                            :icon="expandedKey ? ['fas', 'chevron-up'] : ['fas', 'chevron-down']"
                            class="ml-auto cursor-pointer"
                            @click="toggleExpand()"
                            size="lg"
                        />
                    </div>
                </b-card-header>
                <b-card-body class="statistics-body">
                    <b-row>
                        <b-col
                            xl="3"
                            md="4"
                            sm="12"
                            class="py-1 px-2"
                            v-for="item in statisticsItems"
                            :key="item.icon"
                            :class="[item.customClass, {
                                'background-wrapper': expandedKey === item.key && isExpandKey(item.key) && extraStatisticVisible,
                                'rounded-lg cursor-pointer': isExpandKey(item.key),
                                'mb-xl-0 mb-2': isExpandKey(item.key) && expandedKey !== null && extraStatisticVisible
                            }]"
                        >
                            <b-media no-body @click="toggleExpand(item.key)">
                                <b-media-aside

                                    class="mr-2"
                                >
                                    <b-avatar
                                        size="48"
                                        :variant="item.color"
                                    >
                                        <feather-icon
                                            v-if="item.iconType === 'feather'"
                                            size="24"
                                            :icon="item.icon"
                                        />
                                        <fa
                                            v-else-if="item.iconType === 'fa'"
                                            size="2x"
                                            :icon="item.icon" />
                                        <img alt="icon" v-else-if="item.iconType = 'custom'" :src="item.icon" />
                                    </b-avatar>
                                </b-media-aside>
                                <b-media-body class="my-auto">
                                    <h4 class="font-weight-bolder mb-0">
                                        <span v-if="item.key === 'total_sold' || item.key === 'value_of_open_tables'">
                                            {{ $numberRounding(item.title, 2) }} €
                                        </span>
                                        <span v-else>
                                            {{ item.title }}
                                        </span>
                                    </h4>
                                    <b-card-text class="font-small-3 mb-0">
                                        {{ $t(item.subtitle) }}
                                    </b-card-text>
                                </b-media-body>
                            </b-media>
                        </b-col>
                        <b-col xl="12" md="12" sm="12" class="position-relative p-0">
                            <b-collapse :visible="extraStatisticVisible">
                                <div class="tabsHeader">
                                    <CustomTable
                                        v-if="expandedKey === 'open_tables'"
                                        ref="openTables"
                                        name="openTables"
                                        class="mt-1"
                                        :items="openTablesDetails"
                                        :fields="columnDefsOpenTablesDetails"
                                        :showTableOptions="false"
                                    >
                                        <template #open_tabs="{item}">
                                            <span>{{ item.opened_tabs.length }}</span>
                                        </template>
                                        <template #actions="{item}">
                                            <BButton size="sm" style="min-width: 120px; padding: 5px 10px" @click="viewDetails(item)">
                                                {{ $t('general.details') }}
                                            </BButton>
                                        </template>
                                    </CustomTable>
                                    <CustomTable
                                        v-if="expandedKey === 'value_of_open_tables'"
                                        ref="valueOfOpenTables"
                                        name="valueOfOpenTables"
                                        class="mt-1"
                                        :items="tableData.value_of_open_tables"
                                        :fields="columnDefsValueOfOpenTables"
                                        :showTableOptions="false"
                                    >
                                        <template #actions="{item}">
                                            <BButton size="sm" style="min-width: 120px; padding: 5px 10px" @click="viewSpecificInvoice(item)">
                                                {{ $t('general.details') }}
                                            </BButton>
                                        </template>
                                    </CustomTable>
                                </div>
                            </b-collapse>
                        </b-col>
                    </b-row>
                </b-card-body>
            </b-card>
            <OpenTabDetails ref="openTabDetails" :columnDefs="columnDefsValueOfOpenTables" :viewSpecificInvoice="viewSpecificInvoice"/>
            <OpenOrdersDetails ref="openOrdersDetails"/>
        </b-overlay>
    </div>
</template>
<script>
    import TableIcon from '@/assets/icons/TableIcon.svg'
    import OpenTabDetails from '@/views/Dashboard/components/OpenTabsDetails.vue'
    import OpenOrdersDetails from '@/views/Dashboard/components/OpenOrdersDetails.vue'
    import CustomTable from '@/components/CustomTable.vue'

    import {
        BCollapse,
        BAvatar,
        BCard,
        BCardBody,
        BCardHeader, BCardText,
        BCardTitle,
        BCol,
        BMedia,
        BMediaAside,
        BMediaBody,
        BRow,
        BOverlay,
        BButton
    } from 'bootstrap-vue'
    import * as Sentry from '@sentry/vue'
    import {Formaters} from '@/libs/formaters'

    export default {
        components: {
            BCollapse,
            OpenOrdersDetails,
            CustomTable,
            OpenTabDetails,
            BCardText,
            BCol,
            BMediaBody,
            BCardHeader,
            BCardTitle,
            BAvatar,
            BRow,
            BMediaAside,
            BMedia,
            BCard,
            BCardBody,
            BOverlay,
            BButton
        },

        props: {
            selectedBusinessUnit: {
                type: String
            }
        },
        data() {
            return {
                extraStatisticVisible: false,
                statisticsItemsData: [],
                statisticsItems: [
                    {
                        iconType: 'feather',
                        icon: 'FileTextIcon',
                        color: 'light-primary',
                        title: '',
                        subtitle: 'graphs.num_of_bills',
                        customClass: 'mb-2 mb-xl-0',
                        key: 'total_bills'
                    },
                    {
                        iconType: 'feather',
                        icon: 'AwardIcon',
                        color: 'light-info',
                        title: '',
                        subtitle: 'graphs.sold',
                        customClass: 'mb-2 mb-xl-0',
                        key: 'total_sold'
                    },
                    {
                        iconType: 'custom',
                        icon: TableIcon,
                        color: 'light-danger',
                        title: '',
                        subtitle: 'graphs.open_tables',
                        customClass: 'mb-2 mb-sm-0',
                        key: 'open_tables'
                    },
                    {
                        iconType: 'fa',
                        icon: 'euro-sign',
                        color: 'light-success',
                        title: '',
                        subtitle: 'graphs.open_orders',
                        customClass: '',
                        key: 'value_of_open_tables'
                    }
                ],
                lastUpdated: new Date(),
                dataLoaded: false,
                expandedKey: null,
                tableData: {
                    open_tables: [],
                    value_of_open_tables: []
                },
                openTablesDetails: []
            }
        },

        computed: {
            lastUpdatedFormated() {
                return this.dayjs(this.lastUpdated).format('HH:mm')
            },
            columnDefsOpenTablesDetails() {
                return [
                    {
                        key: 'table_name',
                        label: this.$t('graphs.table_name'),
                        filter: true,
                        filterType: 'input',
                        permissions: true
                    },
                    {
                        key: 'room_name',
                        label: this.$t('graphs.room'),
                        filter: true,
                        filterType: 'input',
                        permissions: true
                    },
                    {
                        key: 'open_tabs',
                        label: this.$t('graphs.number_of_open_invoices')
                    },
                    {
                        key: 'opened_tabs',
                        label: this.$t('graphs.value_of_open_invoices'),
                        formatter: this.calculateTotalOfOpenedTabs
                    },
                    {
                        key: 'timestamp',
                        label: this.$t('graphs.start_of_service'),
                        formatter: Formaters.formatDate,
                        filter: true,
                        filterType: 'date',
                        permissions: true
                    },
                    {
                        key: 'actions',
                        label: this.$t('graphs.options'),
                        permissions: true,
                        disableSelect: true
                    }
                ]
            },
            columnDefsValueOfOpenTables() {
                return [
                    {
                        key: 'table_title',
                        label: this.$t('graphs.table_name'),
                        filter: true,
                        filterType: 'input',
                        permissions: true
                    },
                    {
                        key: 'timestamp_open',
                        label: this.$t('graphs.date_of_creation'),
                        filter: true,
                        filterType: 'date',
                        permissions: true,
                        formatter: Formaters.formatDate
                    },
                    {
                        key: 'products',
                        label: this.$t('graphs.number_of_products'),
                        formatter: this.getProductsLengthOfOpenTabs
                    },
                    {
                        key: 'waiter',
                        label: this.$t('graphs.waiter'),
                        filter: true,
                        filterType: 'input',
                        permissions: true
                    },
                    {
                        key: 'total_price',
                        label: this.$t('graphs.products_value'),
                        formatter: Formaters.formatPrice
                    },
                    {
                        key: 'actions',
                        label: this.$t('graphs.options'),
                        disableSelect: true
                    }
                ]
            }
        },
        methods: {
            async loadData() {
                this.dataLoaded = false
                try {
                    const response =  await this.$http.get('/api/dashboard/v1/dashboard/statistics')
                    this.statisticsItemsData = response.data ?? {}
                    this.openTablesDetails = this.statisticsItemsData.open_tables_details ?? []
                    this.tableData.open_tables = this.openTablesDetails
                    this.tableData.value_of_open_tables = this.openTablesDetails.flatMap(detail => detail.opened_tabs.map(tab => ({
                        table_title: detail.table_name,
                        timestamp_open: tab.timestamp,
                        number_of_items: tab.products.length,
                        waiter: detail.waiter ?? '',
                        total_price: tab.total_price,
                        products: tab.products,
                        cash_register: detail.cash_register_id,
                        room_name: detail.room_name,
                        notes: tab.bill_note?.description || '',
                        business_unit: detail.business_unit
                    }))).sort((a, b) => new Date(b.timestamp_open) - new Date(a.timestamp_open))
                    this.statisticsItems = this.statisticsItems.map((item) => {
                        return {
                            ...item,
                            title: this.statisticsItemsData[item.key]
                        }
                    })
                    this.lastUpdated = new Date()
                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(this.$t('print.error.general'))
                } finally {
                    this.dataLoaded = true
                }
            },
            isExpandKey(key) {
                return key === 'open_tables' || key === 'value_of_open_tables'
            },
            toggleExpand(key) {
                if (this.isExpandKey(key)) {

                    if (!this.extraStatisticVisible) {
                        this.extraStatisticVisible = !this.extraStatisticVisible
                        this.expandedKey = key
                    } else if (this.extraStatisticVisible) {
                        if (this.expandedKey === key) {
                            this.extraStatisticVisible = !this.extraStatisticVisible
                        }

                        this.expandedKey = key
                    }
                    return
                }
                if (key === 'total_bills' || key === 'total_sold') {
                    return
                }
                this.expandedKey = this.expandedKey === null ? 'open_tables' : null
                this.extraStatisticVisible = !this.extraStatisticVisible
            },
            calculateTotalOfOpenedTabs(params) {
                const total = this.$numberRounding(params.reduce((sum, tab) => sum + tab.total_price, 0))
                return `${total}€`
            },
            getProductsLengthOfOpenTabs(params) {
                return params.length
            },
            viewDetails(data) {
                if (this.expandedKey === 'open_tables') {
                    this.viewOpenTablesDetails(data)
                } else {
                    this.viewSpecificInvoice(data)
                }
            },
            viewOpenTablesDetails(data) {
                const tableDetails = this.openTablesDetails.find(detail => detail.table_name === data.table_name && detail.room_name === data.room_name)
                this.$refs.openTabDetails.open(tableDetails)
            },
            viewSpecificInvoice(data) {
                this.$refs.openOrdersDetails.open(data)
            }
        }
    }
</script>

<style lang="scss" scoped>

@media only screen and (min-width: 1200px) {
  .background-wrapper{
    border-radius: 18px 18px 0px 0px !important;
  }
}

@media only screen and (max-width: 1199px) {
  .background-wrapper{
    border-radius: 18px !important;
  }

  .marginBottomTabs{
    margin-bottom: 30px !important;
  }
}

.light-layout .background-wrapper {
    background: #EDEDEDF7 !important;
    padding: 5px;
}

.dark-layout .background-wrapper {
    background-color: rgba(255, 255, 255, 0.1);
    padding: 5px;
}

  .dark-layout .tabsHeader {
    background: rgba(255, 255, 255, 0.1) !important;
    padding: 20px;
    border-radius: 0px 0px 18px 18px !important;
  }

  .light-layout .tabsHeader {
    background: #EDEDEDF7 !important;
    padding: 20px;
    border-radius: 0px 0px 18px 18px !important;
  }

.expand{
  transition: max-height 0.5s; /* this is the key code you need */

}
</style>