<template>
    <b-modal ref="openTabDetailsModal" :title="modalTitle" size="xl" hide-footer>
        <b-card>
            <CustomTable
                :items="tablesData"
                :fields="columnDefs"
                :showTableOptions="false"
                class="mt-2"
            >
                <template #actions="{item}">
                    <BButton size="sm" style="min-width: 120px; padding: 5px 10px" @click="viewSpecificInvoice(item)">
                        {{ $t('general.details') }}
                    </BButton>
                </template>
            </CustomTable>
        </b-card>
    </b-modal>
</template>

<script>
    import CustomTable from '@/components/CustomTable.vue'
    import {
        BModal,
        BCard, BButton
    } from 'bootstrap-vue'

    export default {
        components: {
            BButton,
            CustomTable,
            BModal,
            BCard
        },
        props: {
            columnDefs: Array,
            viewSpecificInvoice: Function
        },
        data() {
            return {
                tablesData: [],
                modalTitle: '',
                tableId: ''
            }
        },
        methods: {
            open(tableDetails) {
                this.modalTitle = tableDetails.table_name
                this.tableId = tableDetails._id
                this.tablesData = tableDetails.opened_tabs
                this.tablesData.forEach(tab => {
                    tab.waiter = tableDetails.waiter ?? ''
                    tab.table_title = tableDetails.table_name
                    tab.room_name = tableDetails.room_name
                    tab.cash_register = tableDetails.cash_register_id
                    tab.business_unit = tableDetails.business_unit
                })
                this.$refs.openTabDetailsModal.show()
            }
        }
    }
</script>