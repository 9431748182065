var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-overlay',{attrs:{"show":!_vm.dataLoaded}},[_c('b-card',{staticClass:"card-statistics",attrs:{"no-body":""}},[_c('b-card-header',[_c('div',{staticClass:"d-flex justify-content-between w-100"},[_c('div',[_c('b-card-title',[_vm._v(_vm._s(_vm.$t('graphs.statistics')))]),_c('b-card-text',{staticClass:"font-small-2 mr-25 mb-0"},[_vm._v(" "+_vm._s(_vm.$t('graphs.last_updated'))+": "+_vm._s(_vm.lastUpdatedFormated)+" ")])],1),_c('fa',{staticClass:"ml-auto cursor-pointer",attrs:{"icon":_vm.expandedKey ? ['fas', 'chevron-up'] : ['fas', 'chevron-down'],"size":"lg"},on:{"click":function($event){return _vm.toggleExpand()}}})],1)]),_c('b-card-body',{staticClass:"statistics-body"},[_c('b-row',[_vm._l((_vm.statisticsItems),function(item){return _c('b-col',{key:item.icon,staticClass:"py-1 px-2",class:[item.customClass, {
                            'background-wrapper': _vm.expandedKey === item.key && _vm.isExpandKey(item.key) && _vm.extraStatisticVisible,
                            'rounded-lg cursor-pointer': _vm.isExpandKey(item.key),
                            'mb-xl-0 mb-2': _vm.isExpandKey(item.key) && _vm.expandedKey !== null && _vm.extraStatisticVisible
                        }],attrs:{"xl":"3","md":"4","sm":"12"}},[_c('b-media',{attrs:{"no-body":""},on:{"click":function($event){return _vm.toggleExpand(item.key)}}},[_c('b-media-aside',{staticClass:"mr-2"},[_c('b-avatar',{attrs:{"size":"48","variant":item.color}},[(item.iconType === 'feather')?_c('feather-icon',{attrs:{"size":"24","icon":item.icon}}):(item.iconType === 'fa')?_c('fa',{attrs:{"size":"2x","icon":item.icon}}):(item.iconType = 'custom')?_c('img',{attrs:{"alt":"icon","src":item.icon}}):_vm._e()],1)],1),_c('b-media-body',{staticClass:"my-auto"},[_c('h4',{staticClass:"font-weight-bolder mb-0"},[(item.key === 'total_sold' || item.key === 'value_of_open_tables')?_c('span',[_vm._v(" "+_vm._s(_vm.$numberRounding(item.title, 2))+" € ")]):_c('span',[_vm._v(" "+_vm._s(item.title)+" ")])]),_c('b-card-text',{staticClass:"font-small-3 mb-0"},[_vm._v(" "+_vm._s(_vm.$t(item.subtitle))+" ")])],1)],1)],1)}),_c('b-col',{staticClass:"position-relative p-0",attrs:{"xl":"12","md":"12","sm":"12"}},[_c('b-collapse',{attrs:{"visible":_vm.extraStatisticVisible}},[_c('div',{staticClass:"tabsHeader"},[(_vm.expandedKey === 'open_tables')?_c('CustomTable',{ref:"openTables",staticClass:"mt-1",attrs:{"name":"openTables","items":_vm.openTablesDetails,"fields":_vm.columnDefsOpenTablesDetails,"showTableOptions":false},scopedSlots:_vm._u([{key:"open_tabs",fn:function(ref){
                        var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.opened_tabs.length))])]}},{key:"actions",fn:function(ref){
                        var item = ref.item;
return [_c('BButton',{staticStyle:{"min-width":"120px","padding":"5px 10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.viewDetails(item)}}},[_vm._v(" "+_vm._s(_vm.$t('general.details'))+" ")])]}}],null,false,2089057405)}):_vm._e(),(_vm.expandedKey === 'value_of_open_tables')?_c('CustomTable',{ref:"valueOfOpenTables",staticClass:"mt-1",attrs:{"name":"valueOfOpenTables","items":_vm.tableData.value_of_open_tables,"fields":_vm.columnDefsValueOfOpenTables,"showTableOptions":false},scopedSlots:_vm._u([{key:"actions",fn:function(ref){
                        var item = ref.item;
return [_c('BButton',{staticStyle:{"min-width":"120px","padding":"5px 10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.viewSpecificInvoice(item)}}},[_vm._v(" "+_vm._s(_vm.$t('general.details'))+" ")])]}}],null,false,1728088635)}):_vm._e()],1)])],1)],2)],1)],1),_c('OpenTabDetails',{ref:"openTabDetails",attrs:{"columnDefs":_vm.columnDefsValueOfOpenTables,"viewSpecificInvoice":_vm.viewSpecificInvoice}}),_c('OpenOrdersDetails',{ref:"openOrdersDetails"})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }