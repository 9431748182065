import {baseChartOption, mergeObjects} from '@/libs/charts/main'

const treeMapChartOption = {
    ...baseChartOption,
    chart: {
        type: 'treemap',
        width: '100%'
    },
    dataLabels: {
        enabled: true
    },
    yaxis: {
        labels: {
            formatter: (item) => {
                return `${(item / 1000000).toFixed(2)} `
            }
        }
    },
    plotOptions: {
        treemap: {
            distributed: true,
            enableShades: false,
            dataLabels: {
                format: 'truncate'
            }
        }
    }
}

function getConfig(rawConfig) {
    if (rawConfig !== null) {
        return mergeObjects(treeMapChartOption, rawConfig)
    } else {
        return treeMapChartOption
    }
}

export default {
    getConfig
}
